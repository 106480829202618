<template>
    <div class="wrapper">

        <div class="info_intro">
            <div class="title">
                <h3 v-html="title"></h3>
            </div>
            <div class="description">
                <p v-html="description"></p>
            </div>

        </div>

        <dynamic-tabs v-if="tabs" @tab="changeTab" :product="tabsProduct" />

        <div class="swiper-container" :class="{swiperDetail: this.styleArrow}">

            <div class="swiper-wrapper" >

                <slot />

            </div>



        </div>
        <div
                @click="arrow()"
                class="swiper-button-next" :class="{styleArrowRight: this.styleArrow}"><i class="arrow right"></i></div>
        <div
                @click="arrowPrev()"
                class="swiper-button-prev" :class="{styleArrowLeft: this.styleArrow}"><i class="arrow left"></i></div>



    </div>



</template>

<script>
    import Swiper, {
        Navigation,
        Pagination
    } from 'swiper';
    Swiper.use([Navigation, Pagination]);
    import 'swiper/swiper-bundle.css';
    import DynamicTabs from "./dynamicTabs";

    export default {
        name: "caruselWrapper",
        props: {
            tabsProduct: Array,
            title: String,
            description: String,
            index: Number,
            tabs: Boolean,
            slidesPerView: {
                type: Number,
                default: 1
            },
            slidesPerViewTables: {
                type: Number,
                default: 2
            },
            slidesPerViewMobile: {
                type: Number,
                default: 1
            },
            styleArrow: Boolean
        },
        data() {
            return {
                swiper: '',
                // number: 1
                activeTabs: 0,

            }
        },
        components: {
            DynamicTabs
        },
        mounted() {
            // eslint-disable-next-line no-unused-vars
            this.swiper = new Swiper('.swiper-container', {
                direction: 'horizontal',
                loop: true,
                simulateTouch: false,
                breakpoints: {
                    1324: {
                        slidesPerView: this.slidesPerView,
                        spaceBetween: 30
                    },
                    1024: {
                        slidesPerView: this.slidesPerViewTables,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: this.slidesPerViewMobile,
                        spaceBetween: 10
                    },
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            let startMoveEvent;

            this.swiper.on("touchStart", event => {
                startMoveEvent = event.touches;
            });

            this.swiper.on("slideChangeTransitionStart", event => {
                let sliderMovedToLeft = event.touches.startX - startMoveEvent.currentX < 0;

                if (event.touches.startX - startMoveEvent.currentX !== 0) {
                    if (sliderMovedToLeft) {
                        this.arrowPrev()
                    } else if (!sliderMovedToLeft){
                        this.arrow();
                    }
                }
            });

            this.swiper.on("slideChangeTransitionEnd", event => {
                let sliderMovedToLeft = event.touches.startX - startMoveEvent.currentX < 0;

                if (event.touches.startX - startMoveEvent.currentX !== 0) {
                    if (sliderMovedToLeft) {
                        this.viewsItem(this.$store.state.number)
                    } else if (!sliderMovedToLeft){
                        this.viewsItem(this.$store.state.number)
                    }
                }
            });
        },

        methods: {
            changeTab(index) {
                this.swiper.slideToLoop(index);
                this.$store.state.number = index
            },
            arrow() {
                this.$store.dispatch('SET_NUMBER')
            },
            arrowPrev() {
                this.$store.dispatch('SET_NUMBER_PREV')
            },
            viewsItem(item) {
                document.querySelectorAll('.scrollRight')[0].querySelectorAll('li')[item].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            },

        },

    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .wrapper {
        display: flex;
        flex-direction: column;

        & .info_intro {
            text-align: center;
            padding-top: 100px;
            margin: 0 10px;

            @media (max-width: 992px) {
                padding-top: 60px;
            }

            @media (max-width: 575px) {
                padding-top: 30px;
            }

            & .title {
                & h3 {
                    @include title
                }

            }

            & .description {
                & p {
                    @include description
                }
            }
        }

    }

    .swiperDetail {
        position: relative;
        max-width: 1300px !important;
        margin: 0 auto !important;


    }

    .styleArrowLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 65%;
        left: 0 !important;

            @include btnNextPrev($swiperPrev);

            @media (max-width: 768px) {
                display: none;
            }

        i {
            width: 10px;
            height: 10px;
            display: block;
        }

        .right {
            @include arrowRightLeft(-45deg, white)
        }

        .left {
            @include arrowRightLeft(135deg, $base-color)
        }
    }

    .styleArrowRight {
        top: 65%;
        right: 0 !important;

            @include btnNextPrev($swiperNext);

            @media (max-width: 768px) {
                display: none;
            }

        i {
            width: 10px;
            height: 10px;
            display: block;
        }

        .right {
            @include arrowRightLeft(-45deg, white)
        }

        .left {
            @include arrowRightLeft(135deg, $base-color)
        }

    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    i {
        width: 10px;
        height: 10px;
        display: block;
    }

    .right {
        @include arrowRightLeft(-45deg, white)
    }

    .left {
        @include arrowRightLeft(135deg, $base-color)
    }

     .swiper-button-prev {
         top: 65%;
         left: 5px !important;

        @include btnNextPrev($swiperPrev);

        @media (max-width: 992px) {
            display: none;
        }
    }

     .swiper-button-next {
         top: 65%;
         right: 0 !important;
        @include btnNextPrev($swiperNext);

        @media (max-width: 992px) {
            display: none;
        }
    }

     .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
        content: none !important;
    }

     .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
        content: none !important;
    }

</style>
