<template>
    <div class="swiper-slide">

        <slot />

    </div>
</template>

<script>
    export default {
        name: "caruselItem"
    }
</script>

<style scoped lang="scss">
    .swiper-slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50%;


        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>