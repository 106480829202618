<template>
    <section class="tabs">

        <ul class="scrollRight">
            <li v-for="(tabsItem, index) in product" :id='index' :key="tabsItem[index]" @click="tab(index)"
                :class="[{active: number === index}, style]">
                <p>{{tabsItem.title}}</p>
            </li>

        </ul>

    </section>
</template>

<script>
    export default {
        props: {
            product: Array,
            activeTabs: Number
        },
        name: "dynamicTabs",
        data() {
            return {
                number: this.$store.state.number,
                width: window.innerWidth,
                activeLink: document.querySelectorAll('.active'),
            }
        },
        methods: {
            tab: function (index) {
                this.number = index;
                this.$store.state.number = index;
                this.$emit('tab', index)
            },
            updateWidth() {
                this.width = window.innerWidth;
            },

        },

        mounted() {
            window.addEventListener('resize', this.updateWidth);
            this.updateWidth()
        },


        computed: {
            count () {
                return this.$store.state.number
            },
            style() {
                if (this.width < 993) {
                    return 'liMobile'
                } else {
                    return 'liDesctop'
                }
            }
        },
        watch: {
            count(newCount) {
               this.number = newCount
            },
        }




    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .tabs {
        margin: 60px 40px 0 40px;

        @media (max-width: 1200px) {
            margin: 60px 20px 0 20px;
        }

        @media (max-width:1000px) {
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            margin: 8px;
        }


        & ul {
            display: flex;
            justify-content: space-around;

            @media (max-width:992px) {
                justify-content: flex-start;
                padding: 0;
            }

            & .active {
                background: $colorLinkHover !important;
                transition: $transitionLink !important;
                color: white;

                & p {
                    color: white;
                }
            }

            & .liDesctop {
                display: inline-flex !important;
                justify-content: center !important;
                align-items: center !important;
                width: auto !important;
                min-width: auto !important;
                padding: 0 10px;
                @include btnSafety(white, $colorLinkActive, $colorLinkHover);
                margin-right: 5px;


                @media(max-width: 575px) {
                    height: 30px;
                    margin-right: 10px;
                }


                & p {
                    font-size: 15px;
                    font-weight: 900;
                    margin: 1px;

                    @media(max-width: 1300px) {
                        font-size: 14px;
                    }

                    @media(max-width: 992px) {
                        font-size: 13px;
                    }

                    @media(max-width: 575px) {
                        font-size: 12px;
                    }
                }
            }
            & .liMobile {
                    width: auto !important;
                    min-width: 150px !important;
                    padding: 0 10px;
                    margin-right: 5px;

                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    height: 55px;

                    border-radius: 50px;
                    text-decoration: none;
                    cursor: pointer;
                    background-color: white;
                    border: 1px solid $colorLinkActive;
                    transition: $transitionLink;

                    & svg {
                        width: 20px;
                        height: 14px;
                        margin: auto;
                    }


                    &:focus {
                        outline: none;
                    }

                @media(max-width: 575px) {
                    height: 30px;
                    margin-right: 10px;
                }


                & p {
                    font-size: 15px;
                    font-weight: 900;
                    margin: 1px;

                    @media(max-width: 992px) {
                        font-size: 13px;
                    }

                    @media(max-width: 575px) {
                        font-size: 12px;
                    }
                }


                    @media (max-width: 1024px) {
                        width: 250px;
                        min-width: auto !important;
                    }

                    @media (max-width: 768px) {
                        width: 135px;
                        min-width: auto !important;
                    }
            }

        }
    }
</style>
